import Vue from 'vue';
import VModal from 'vue-js-modal';
import i18n from '@/i18n';

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

async function getComponent(component) {
  let result;
  if (typeof component === 'function') {
    try {
      Vue.prototype.$Progress.start();
      const module = await component();
      result = module.default;
    } catch (e) {
      Vue.prototype.$notify({
        data: {
          type: 'error',
          content: i18n.t('notify.fail', {
            message: e.message,
          }),
        },
      });
    } finally {
      Vue.prototype.$Progress.finish();
    }
  } else {
    result = await component;
  }
  return result;
}

export async function show(component, ...args) {
  return Vue.prototype.$modal.show(
    await getComponent(component),
    ...args,
  );
}

export const { hide } = Vue.prototype.$modal;
